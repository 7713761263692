/* eslint-disable jsx-a11y/anchor-is-valid */
// import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import PieChart, {
	Legend,
	Series,
	Tooltip,
	Format,
	Label,
	Connector,
	Export,
} from 'devextreme-react/pie-chart';
import {
	DashboardWidget1
} from '../../../_metronic/partials/widgets/dashboard/DashboardWidget1';
import data from './data/dummy.json'

const customizeTooltip = (arg) => {
	return {
		text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
	};
}

const formatNumber = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 0,
}).format;

function calculateTotal(pieChart) {
	return formatNumber(pieChart
		.getAllSeries()[0]
		.getVisiblePoints()
		.reduce((s, p) => s + p.originalValue, 0));
}


function TooltipTemplate(pieChart) {
	// const { status } = pieChart.getAllSeries()[0].getVisiblePoints()[0].data;
	return (
		<svg>
			<circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle>
			<text textAnchor="middle" x="100" y="100" style={{ fontSize: 18, fill: '#494949' }}>
				<tspan x="100">Total</tspan>
				<tspan x="100" dy="20px" style={{ fontWeight: 600 }}>{
					calculateTotal(pieChart)
				}</tspan>
			</text>
		</svg>
	);
}

function customizeLabel(e) {
	return `${e.argumentText}\n${e.valueText}`;
}

const DashboardPage = () => (
	<>
		{/* {console.log("props", props)} */}
		{/* begin::Row */}
		<div className='row g-5 g-xl-8'>
			<div className='col-xl-4'>
				<div className='card card-xl-stretch mb-xl-8'>
					{/* begin::Header */}
					<div className='card-header border-0 pt-5'>
						<h3 className='card-title align-items-start flex-column'>
							<span className='card-label fw-bold text-dark'>Summary </span>
							<span className='text-muted mt-1 fw-semibold fs-7'>Latest status assets</span>
						</h3>
						<div className='card-toolbar'>
							{/* begin::Menu */}
							<button
								type='button'
								className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
								data-kt-menu-trigger='click'
								data-kt-menu-placement='bottom-end'
								data-kt-menu-flip='top-end'
							>
								<KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
							</button>
							{/* end::Menu */}
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Body */}
					<div className='card-body pt-5'>
						{/* begin::Item */}

						{/* end::Symbol */}
						{/* begin::Section */}
						{data.map((i) => (<div className='d-flex align-items-sm-center mb-7'>
							{/* begin::Symbol */}
							<div className='symbol symbol-50px me-5'>
								<span className='symbol-label'>
									<img
										src={toAbsoluteUrl('/media/icons/duotune/files/fil001.svg')}
										className='h-50 align-self-center'
										alt=''
									/>
								</span>
							</div><div className='d-flex align-items-center flex-row-fluid flex-wrap'>
								<div className='flex-grow-1 me-2'>
									<a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
										{i.status}
									</a>
									{/* <span className='text-muted fw-semibold d-block fs-7'>Mark, Rowling, Esther</span> */}
								</div>
								<span className='badge badge-light fw-bold my-2'>{i.val}</span>
							</div></div>))}
						{/* end::Section */}

					</div>
					{/* end::Body */}
				</div>
			</div>
			<div className='col-xl-8'>
				<div className='card card-xl-stretch mb-xl-8'>
					<div className='card-body pt-5'>
						<PieChart
							id="pie"
							type="doughnut"
							title="The summary of Assets status"
							palette="Soft Pastel"
							dataSource={data}
							centerRender={TooltipTemplate}
						>
							<Series argumentField="status">
								<Label visible={true} format="fixedPoint" customizeText={customizeLabel}>
									<Connector visible={true} />
								</Label>
							</Series>
							<Export enabled={true} />
							<Legend
								margin={0}
								horizontalAlignment="right"
								verticalAlignment="top"
								visible={false}
							/>
							<Tooltip enabled={true} customizeTooltip={customizeTooltip}>
								<Format type="fixedPoint" />
							</Tooltip>
						</PieChart>
					</div>
				</div>
			</div>
		</div>
		{/* <div className='row mb-4'>
			<h4 className="my-3">Status Assets</h4>
			<div className='col-sm-6 col-md-4 col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-4'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='info'
					iconColor='white'
					titleColor='white'
					title='Active Assets'
					contents={["23.032 Assets"]}
					contentColor='white'
				/>
			</div>
			<div className='col-sm-6 col-md-4 col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-4'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='info'
					iconColor='white'
					titleColor='white'
					title='Registered Assets'
					contents={["41.023 Assets"]}
					contentColor='white'
				/>
			</div>
			<div className='col-sm-6 col-md-4 col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-4'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='info'
					iconColor='white'
					titleColor='white'
					title='Removed Assets'
					contents={["17.991 Assets"]}
					contentColor='white'
				/>
			</div>
			<div className='col-sm-6 col-md-4 col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-4'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='info'
					iconColor='white'
					titleColor='white'
					title='In-Transit Assets'
					contents={["241 Assets"]}
					contentColor='white'
				/>
			</div>
		</div> */}

		{/* <div className='row mb-4'>
			<h4 className="my-3">Transaksi (Progress)</h4>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='default'
					iconColor='dark'
					titleColor='dark'
					title='Perpindahan'
					contents={["2 Asset(s) in", "2 No. Transaksi"]}
					contentColor='dark'
				/>
			</div>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='default'
					iconColor='dark'
					titleColor='dark'
					title='Penghapusan'
					contents={["1 Asset(s) in", "1 No. Transaksi"]}
					contentColor='dark'
				/>
			</div>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='default'
					iconColor='dark'
					titleColor='dark'
					title='Transaksi Keluar'
					contents={["1 Asset(s) in", "1 No. Transaksi"]}
					contentColor='dark'
				/>
			</div>
		</div>

		<div className='row mb-4'>
			<h4 className="my-3">Persetujuan</h4>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='success'
					iconColor='white'
					titleColor='white'
					title='Perpindahan'
					contents={["1 Asset(s) in", "1 No. Transaksi"]}
					contentColor='white'
				/>
			</div>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='success'
					iconColor='white'
					titleColor='white'
					title='Penghapusan'
					contents={["1 Asset(s) in", "1 No. Transaksi"]}
					contentColor='white'
				/>
			</div>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='success'
					iconColor='white'
					titleColor='white'
					title='Transaksi Keluar'
					contents={["1 Asset(s) in", "1 No. Transaksi"]}
					contentColor='white'
				/>
			</div>
		</div>

		<div className='row mb-4'>
			<h4 className="my-3">Unprint</h4>
			<div className='col-lg-4'>
				<DashboardWidget1
					className='card-xl-stretch mb-2'
					svgIcon='/media/icons/duotune/general/gen032.svg'
					color='danger'
					iconColor='white'
					titleColor='white'
					title='Perpindahan'
					contents={["3 Asset(s)"]}
					contentColor='white'
				/>
			</div>
		</div> */}
	</>
)

const DashboardWrapper = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
			<DashboardPage />
		</>
	)
}

export default DashboardWrapper;

