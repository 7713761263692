import React, { useEffect } from 'react';

import { PageTitle } from '../../../_metronic/layout/core'

//-- Config
import { connect } from 'react-redux'

const usersBreadcrumbs = [
	// {
	// 	title: '',
	// 	path: '',
	// 	isSeparator: false,
	// 	isActive: false,
	// },
	// {
	// 	title: 'Vendor Detail',
	// 	path: '/apps/user-management/users',
	// 	isSeparator: false,
	// 	isActive: false,
	// },
	// {
	// 	title: 'Vendor',
	// 	path: '23',
	// 	isSeparator: true,
	// 	isActive: false,
	// },
	// {
	// 	title: 'User Management',
	// 	path: '/apps/user-management/users',
	// 	isSeparator: false,
	// 	isActive: false,
	// },
	// {
	// 	title: '',
	// 	path: '23',
	// 	isSeparator: true,
	// 	isActive: true,
	// },
]

const VendorPage = () => {
	useEffect(() => {
	}, []);
	return (
		<>
			<PageTitle breadcrumbs={usersBreadcrumbs}>Vendor</PageTitle>

			<div className='row gy-5 g-xl-8'>
				<div className='col-xxl-12'>
					<h2>Vendor Content</h2>
				</div>
			</div>
		</>
	)
}

const reduxState = (state) => ({
	user: state.auth.user,
})

export default connect(reduxState, null)(VendorPage);
