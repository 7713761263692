import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core'
import { Card } from 'antd';
import TaskGrid from './components/TaskGrid';

const TaskPage = ({
	PageTitle,
	PageHeader,
	IsPageHeaderVisible
}) => (
	<>
		<Card
			title={IsPageHeaderVisible ? <p className='px-2 py-2 mb-0'>{PageHeader}</p> : null}
			size='small'
			bordered={false}>
			<div className='px-2 py-1'>
				<TaskGrid />
			</div>
		</Card>
	</>
)

const TaskWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Task</PageTitle>
			<TaskPage PageTitle={"Task"} PageHeader="Task" IsPageHeaderVisible={true} />
		</>
	)
}

export default TaskWrapper;

