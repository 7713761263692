import cookies from "../../cookies";

const initState = {
    isLogin: cookies.get() ? true : false,
    authError: "",
    errorMessage: "",
    user: cookies.get(),
}

const authReducer = (state = initState, res) => {
    switch (res.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                errorMessage: ""
            }
        case "LOGIN_FAILED":
            return {
                ...state,
                errorMessage: res.value
            }
        case "CHANGE_LOADING":
            return {
                ...state,
                isLoading: res.value
            }
        case "FORM_UPDATE":
            return {
                ...state,
                authError: "",
                errorMessage: ""
            }
        default:
            return state
    }
}

export default authReducer;