import axiosApi from '../../axiosConfig';
import cookies from '../../cookies';

export const actionFormUpdate = () => (dispatch) => {
    return dispatch({ type: 'FORM_UPDATE', value: "" });
}

export const userLogin = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: "CHANGE_LOADING", value: true });
        var user = {
            "id": "e402c3dc-dfca-4f35-b102-38718f00ccb1",
            "first_name": "Alex",
            "last_name": "Yahya",
            "email": data.email,
            "full_name": "Alex Yahya",
        }
        cookies.set(user);
        dispatch({ type: "LOGIN_SUCCESS", user: user });
        resolve(true);

        // axiosApi.post(`/auth/login`, data
        // ).then(res => {
        //     var r = res.data;
        //     if (r.status) {
        //         console.log(r);
        //         cookies.set(user);
        //         dispatch({ type: "LOGIN_SUCCESS", user: user });
        //         //resolve(true);
        //     } else {
        //         dispatch({ type: "CHANGE_LOADING", value: false });
        //         dispatch({ type: "LOGIN_FAILED", value: r.message });
        //         reject(false);
        //     }
        // }).catch(error => {
        //     dispatch({ type: "CHANGE_LOADING", value: false });
        // });
    })
}