import { Provider } from 'react-redux';
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/login'

import store from '../config/redux/store';

import 'devextreme/dist/css/dx.light.css';

export default function App() {
	return (
		<Provider store={store}>
			<Suspense fallback={<LayoutSplashScreen />}>
				<I18nProvider>
					<LayoutProvider>
						<AuthInit>
							<Outlet />
							<MasterInit />
						</AuthInit>
					</LayoutProvider>
				</I18nProvider>
			</Suspense>
		</Provider>
	);
}


// const App = ({ store }) => {
// 	return (
		
// 	)
// }

// export default App
