/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useEffect } from 'react'
import React from 'react'
import clsx from 'clsx';

// import { useAuth } from '../../../../app/modules/login'
// import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { KTSVG } from '../../../helpers';

import { useThemeMode } from '../theme-mode/ThemeModeProvider'
import cookies from '../../../../config/cookies';

//-- Config
import { connect } from 'react-redux'

const HeaderUserMenu = (props) => {
	// const { currentUser } = useAuth()
	const { mode, updateMode, updateMenuMode } = useThemeMode()

	const switchMode = (_mode) => {
		updateMenuMode(_mode);
		updateMode(_mode);
	}
	// useEffect(() => {
	// 	switchMode('light')
	// }, [])


	const logout = () => {
		// alert();
		cookies.remove();
		window.location.reload();
	}

	return (
		<div
			className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
			data-kt-menu='true'
		>
			<div className='menu-item px-3'>
				<div className='menu-content d-flex align-items-center px-3'>
					<div className='symbol symbol-50px me-5'>
						<img alt='Logo' src={toAbsoluteUrl('/media/avatars/photo-profile.jpg')} />
					</div>

					<div className='d-flex flex-column'>
						<div className='fw-bolder d-flex align-items-center fs-5'>
							{props?.user?.full_name}
							{/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
						</div>
						<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
							{props?.user?.email}
						</a>
					</div>
				</div>
			</div>

			<div className='separator my-2'></div>

			<div
				className='menu-item px-5 d-none'
				data-kt-menu-trigger='hover'
				data-kt-menu-placement='left-start'
				data-kt-menu-flip='bottom'
			>
				<a href='#' className='menu-link px-3'>
					<span className='menu-title'>
						{/* <KTSVG
							path='/media/icons/duotune/general/gen061.svg'
							className={clsx('theme-light-hide', 'svg-icon-2')}
						/> */}

						{mode === 'dark' && (
							<KTSVG
								path='/media/icons/duotune/general/gen061.svg'
								className={clsx('theme-light-hide', 'svg-icon-2')}
							/>
						)}

						{mode === 'light' && (
							<KTSVG
								path='/media/icons/duotune/general/gen060.svg'
								className={clsx('theme-dark-hide', 'svg-icon-2')}
							/>
						)}
						<span className='ms-2'>Mode</span>
					</span>
					<span className='menu-arrow'></span>
				</a>

				<div className='menu-sub menu-sub-dropdown w-175px py-4'>
					<div className='menu-item px-3'>
						<a href='#' className='menu-link px-3' onClick={() => switchMode('light')}>
							<KTSVG
								path='/media/icons/duotune/general/gen060.svg'
								className={clsx('theme-dark-hide', 'svg-icon-2')}
							/>
							<span className='ms-2'>Terang</span>
						</a>
						<a href='#' className='menu-link px-3' onClick={() => switchMode('dark')}>
							<KTSVG
								path='/media/icons/duotune/general/gen061.svg'
								className={clsx('theme-light-hide', 'svg-icon-2')}
							/>
							<span className='ms-2'>Malam</span>
						</a>
					</div>
				</div>
			</div>

			<div className='menu-item px-5'>
				<a onClick={() => logout()} className='menu-link px-3'>
					<KTSVG
						path='/media/icons/duotune/arrows/arr077.svg'
						className={clsx('theme-light-hide', 'svg-icon-2')}
					/>
					<span className='ms-2'>Logout</span>
				</a>
			</div>

			{/* <div className='menu-item px-5'>
				<Link to={'/crafted/pages/profile'} className='menu-link px-5'>
					My Profile
				</Link>
			</div> */}

			{/* <div className='menu-item px-5'>
				<a href='#' className='menu-link px-5'>
					<span className='menu-text'>My Projects</span>
					<span className='menu-badge'>
						<span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
					</span>
				</a>
			</div>

			<div
				className='menu-item px-5'
				data-kt-menu-trigger='hover'
				data-kt-menu-placement='left-start'
				data-kt-menu-flip='bottom'
			>
				<a href='#' className='menu-link px-5'>
					<span className='menu-title'>My Subscription</span>
					<span className='menu-arrow'></span>
				</a>

				<div className='menu-sub menu-sub-dropdown w-175px py-4'>
					<div className='menu-item px-3'>
						<a href='#' className='menu-link px-5'>
							Referrals
						</a>
					</div>

					<div className='menu-item px-3'>
						<a href='#' className='menu-link px-5'>
							Billing
						</a>
					</div>

					<div className='menu-item px-3'>
						<a href='#' className='menu-link px-5'>
							Payments
						</a>
					</div>

					<div className='menu-item px-3'>
						<a href='#' className='menu-link d-flex flex-stack px-5'>
							Statements
							<i
								className='fas fa-exclamation-circle ms-2 fs-7'
								data-bs-toggle='tooltip'
								title='View your statements'
							></i>
						</a>
					</div>

					<div className='separator my-2'></div>

					<div className='menu-item px-3'>
						<div className='menu-content px-3'>
							<label className='form-check form-switch form-check-custom form-check-solid'>
								<input
									className='form-check-input w-30px h-20px'
									type='checkbox'
									value='1'
									defaultChecked={true}
									name='notifications'
								/>
								<span className='form-check-label text-muted fs-7'>Notifications</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			<div className='menu-item px-5'>
				<a href='#' className='menu-link px-5'>
					My Statements
				</a>
			</div>

			<div className='separator my-2'></div>

			<Languages />

			<div className='menu-item px-5 my-1'>
				<Link to='/crafted/account/settings' className='menu-link px-5'>
					Account Settings
				</Link>
			</div> */}
		</div>
	)
}

const reduxState = (state) => ({
	user: state.auth.user,
})

export default connect(reduxState, null)(HeaderUserMenu);
