import React, { lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import VendorPage from '../pages/vendor/index';
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
// import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TaskWrapper from '../pages/task/TaskWrapper'

// import Pengadaan from '../modules/perencanaan/rk/pengadaan'

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

	const PerencanaanPage = lazy(() => import('../modules/perencanaan/PerencanaanPage'))

	// Perolehan
	const PenerimaanBarangPage = lazy(() => import('../modules/perolehan/penerimaan-barang/PenerimaanBarangPage'))
	const PerolehanBMNPage = lazy(() => import('../modules/perolehan/bmn/PerolehanBMNPage'))

	const PlanningPage = lazy(() => import('../pages/planning/PlanningPage'))

	// Master Data
	const AssetManagementPage = lazy(() => import('../pages/assets-management/AssetManagementPage'))
	const ProcurementPage = lazy(() => import('../pages/procurement/ProcurementPage'))
	const MasterDataPage = lazy(() => import('../modules/master-data/MasterDataPage'))
	const LocationPage = lazy(() => import('../modules/location/LocationPage'))
	// const PersonelPage = lazy(() => import('../modules/master-data/personel/PersonelPage'))
	// const SettingsPage = lazy(() => import('../pages/settings/SettingsPage'))
	const MaintenancesRepairsPage = lazy(() => import('../pages/maintenances-repairs/MaintenancesRepairsPage'))

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path='auth/*' element={<Navigate to='/dashboard' />} />
				{/* Pages */}

				{/* <Route exact path='/perencanaan/rk/pengadaan' element={<Pengadaan />} /> */}

				<Route path='asset-management/*' element={<SuspensedView><AssetManagementPage /></SuspensedView>} />
				
				<Route path='procurement/*' element={<SuspensedView><ProcurementPage /></SuspensedView>} />

				{/* <Route path='master-data/personels/*' element={<SuspensedView><PersonelPage /></SuspensedView>} /> */}
				<Route path='master-data/*' element={<SuspensedView><MasterDataPage /></SuspensedView>} />
				
				<Route path='location/*' element={<SuspensedView><LocationPage /></SuspensedView>} />

				<Route path='perencanaan/*' element={<SuspensedView><PerencanaanPage /></SuspensedView>} />
				<Route path='planning/*' element={<SuspensedView><PlanningPage /></SuspensedView>} />
				{/* <Route path='settings/*' element={<SuspensedView><SettingsPage /></SuspensedView>} /> */}
				<Route path='maintenances-repairs/*' element={<SuspensedView><MaintenancesRepairsPage /></SuspensedView>} />



				{/* Perolehan Barang Page */}
				<Route
					path='perolehan/penerimaan/*'
					element={
						<SuspensedView>
							<PenerimaanBarangPage />
						</SuspensedView>
					}
				/>
				<Route
					path='perolehan/bmn/*'
					element={
						<SuspensedView>
							<PerolehanBMNPage />
						</SuspensedView>
					}
				/>
				{/* End of Perolehan Page */}



				<Route path='dashboard' element={<DashboardWrapper />} />
				<Route path='task' element={<TaskWrapper />} />
				<Route path='vendor' element={<VendorPage />} />
				<Route path='builder' element={<BuilderPageWrapper />} />
				<Route path='menu-test' element={<MenuTestPage />} />
				{/* Lazy Modules */}
				<Route
					path='crafted/pages/profile/*'
					element={
						<SuspensedView>
							<ProfilePage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/pages/wizards/*'
					element={
						<SuspensedView>
							<WizardsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/widgets/*'
					element={
						<SuspensedView>
							<WidgetsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/account/*'
					element={
						<SuspensedView>
							<AccountPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/chat/*'
					element={
						<SuspensedView>
							<ChatPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/user-management/*'
					element={
						<SuspensedView>
							<UsersPage />
						</SuspensedView>
					}
				/>
				{/* Page Not Found */}
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView = ({ children }) => {
	const baseColor = getCSSVariableValue('--kt-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
