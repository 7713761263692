import React from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
// import { getUserByToken, login } from '../core/_requests'
// import { login } from '../core/_requests'

import { connect } from 'react-redux'
// import { useAuth } from '../core/Auth'
// import cookies from '../../../../config/cookies'

import { userLogin, actionFormUpdate } from '../../../../config/redux/action'

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Format Email masih salah.')
		.min(3, 'Minimal 3 karakter')
		.max(50, 'Maksimal 50 karakter')
		.required('Email tidak boleh kosong.'),
	password: Yup.string()
		.min(3, 'Minimal 3 karakter')
		.max(50, 'Maksimal 50 karakter')
		.required('Password tidak boleh kosong.'),
})

const initialValues = {
	email: 'admin@polri.go.id',
	password: 'admin',
}

const Login = (props) => {
	const { isLoading, errorMessage } = props;
	// const { saveAuth, setCurrentUser, auth } = useAuth()

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			handleLogin(values.email, values.password);
			// const res = await props.userLogin({"as", "asd"})
			// .catch(err => err);

			// console.log(res);
			// try {
			// const result = await login(values.email, values.password)
			// 	var r = result.data;
			// 	console.log("r", r)
			// 	console.log("props", props)
			// 	if (r.status) {
			// 		saveAuth(r.data);
			// 		console.log("r", r.data);
			// 		setCurrentUser(r.data)

			// 		cookies.set(r.data);

			// 		// const { data: user } = await getUserByToken(auth.api_token)
			// 	} else {
			// 		setStatus(r.message);
			// 		return;
			// 	}

			// } catch (error) {
			// 	saveAuth(undefined)
			// 	setStatus('The login detail is incorrect')
			// 	setSubmitting(false)
			// 	setLoading(false)
			// }
		},
	})

	const handleLogin = async (email, password) => {
		const res = await props.userLogin({ email, password })
			.catch(err => err);

		console.log("res handleLogin", res);
		if (res) {
			window.location.assign("/dashboard");
			//setTimeout(() => router.push('/'), 1500);
		}
	}

	return (
		<form
			className='form w-100'
			onSubmit={formik.handleSubmit}
			noValidate
			id='kt_login_signin_form'>

			{console.log("props", props)}

			<div className='text-center mb-10 d-none'>
				<h1 className='text-dark mb-3'>Masuk</h1>
			</div>

			{errorMessage && (
				<div className='mb-lg-5 alert alert-danger'>
					<div className='alert-text fw-bold'>{errorMessage}</div>
				</div>
			)}

			<div className='fv-row mb-10'>
				<label className='form-label fs-6 fw-bolder text-dark'>Email</label>
				<input
					placeholder='Email'
					{...formik.getFieldProps('email')}
					onKeyDown={() => props.actionFormUpdate()}
					className={clsx(
						'form-control form-control-sm form-control-solid',
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
					type='email'
					name='email'
					autoComplete='off'
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<span className='text-danger'>{formik.errors.email}</span>
					</div>
				)}
			</div>

			<div className='fv-row mb-5'>
				<div className='d-flex justify-content-between mt-n5'>
					<div className='d-flex flex-stack mb-2'>
						<label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
					</div>
				</div>
				<input
					placeholder='Password'
					type='password'
					// autoComplete='off'
					{...formik.getFieldProps('password')}
					onKeyDown={() => props.actionFormUpdate()}
					className={clsx(
						'form-control form-control-sm form-control-solid',
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span className='text-danger'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			<div className='text-center'>
				<button
					type='submit'
					id='kt_sign_in_submit'
					className='btn btn-sm btn-primary w-100 mb-5'
					disabled={isLoading || !formik.isValid}
				>
					{!isLoading && <span className='indicator-label'>Login</span>}
					{isLoading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							<span className='spinner-border spinner-border-sm align-middle me-2'></span>
							Please wait...
						</span>
					)}
				</button>
			</div>
		</form>
	)
}

const reduxState = (state) => ({
	user: state.auth.user,
	isLoading: state.auth.isLoading,
	errorMessage: state.auth.errorMessage,
})

const reduxDispatch = (dispatch) => ({
	userLogin: (data) => dispatch(userLogin(data)),
	actionFormUpdate: () => dispatch(actionFormUpdate())
	// userLoginWithGoogle: (data) => dispatch(userLoginWithGoogle(data)),
	// actionFormUpdate: () => dispatch(actionFormUpdate())
})

export default connect(reduxState, reduxDispatch)(Login);