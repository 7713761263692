const SideMenu = [
    {
        path: "/dashboard",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Dashboard",
        fontIcon: "bi-app-indicator",
        children: []
    },
    {
        path: "/task",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Task",
        fontIcon: "bi-app-indicator",
        children: []
    },

    {
        path: "/asset-management",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Assets Management",
        fontIcon: "bi-archive",
        children: [
            {
                path: "/asset-management/assets",
                icon: "",
                title: "Assets",
                fontIcon: "",
                children: []
            },
            {
                path: "/asset-management/good-received-note",
                icon: "",
                title: "Good Received Note",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/good-released-note",
                icon: "",
                title: "Good Released Note",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/asset-registration",
                icon: "",
                title: "Asset Registration",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/return",
                icon: "",
                title: "Return",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/lending",
                icon: "",
                title: "Lending",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/allocation",
                icon: "",
                title: "Allocation",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/relocation",
                icon: "",
                title: "Relocation",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/maintenance",
                icon: "",
                title: "Maintenance",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/repair",
                icon: "",
                title: "Repair",
                fontIcon: "",
                children: []
            }, {
                path: "/asset-management/disposal",
                icon: "",
                title: "Disposal",
                fontIcon: "",
                children: []
            }
        ]
    },
    {
        path: "/procurement",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Procurement",
        fontIcon: "bi-archive",
        children: [
            {
                path: "/procurement/assets",
                icon: "",
                title: "Assets",
                fontIcon: "",
                children: []
            },
            {
                path: "/procurement/maintenances",
                icon: "",
                title: "Maintenances",
                fontIcon: "",
                children: []
            },
            {
                path: "/procurement/repairs",
                icon: "",
                title: "Repairs",
                fontIcon: "",
                children: []
            },
        ]
    },
    {
        path: "/planning",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Planning",
        fontIcon: "bi-archive",
        children: [
            {
                path: "/planning/asset",
                icon: "",
                title: "Asset",
                fontIcon: "",
                children: []
            },
            {
                path: "/planning/maintenance",
                icon: "",
                title: "Maintenance",
                fontIcon: "",
                children: []
            },
            {
                path: "/planning/repair",
                icon: "",
                title: "Repair",
                fontIcon: "",
                children: []
            },
        ]
    },
    // {
    //     path: "/organization",
    //     icon: "/media/icons/duotune/art/art002.svg",
    //     title: "Organization",
    //     fontIcon: "bi-archive",
    //     children: [
    //         {
    //             path: "/organization/structure",
    //             icon: "",
    //             title: "Structure & Organization",
    //             fontIcon: "",
    //             children: []
    //         }
    //     ]
    // },
    {
        path: "/master-data",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Master Data",
        fontIcon: "bi-archive",
        children: [{
            path: "/master-data/asset-types",
            icon: "",
            title: "Asset Types",
            fontIcon: "",
            children: []
        }, {
            path: "/master-data/asset-classifications",
            icon: "",
            title: "Asset Classifications",
            fontIcon: "",
            children: []
        },
        {
            path: "/master-data/depreciations",
            icon: "",
            title: "Depreciations",
            fontIcon: "",
            children: []
        },
        {
            path: "/master-data",
            icon: "/media/icons/duotune/art/art002.svg",
            title: "Organization",
            fontIcon: "bi-app-indicator",
            children: [{
                path: "/master-data/organization/structure",
                icon: "",
                title: "Structures",
                fontIcon: "",
                children: []
            }, {
                path: "/master-data/work-unit",
                icon: "",
                title: "Work Unit",
                fontIcon: "",
                children: []
            },
            {
                path: "/master-data/personel",
                icon: "",
                title: "Personel",
                fontIcon: "",
                children: []
            }]
        },
            // {
            //     path: "/master-data/vendors",
            //     icon: "/media/icons/duotune/art/art002.svg",
            //     title: "Vendors",
            //     fontIcon: "bi-app-indicator",
            //     children: []
            // },
            // {
            //     path: "/perolehan/penerimaan",
            //     icon: "",
            //     title: "Location",
            //     fontIcon: "",
            //     children: [{
            //         path: "/perolehan/penerimaan/pencatatan",
            //         icon: "",
            //         title: "Building",
            //         fontIcon: "",
            //         children: []
            //     }, {
            //         path: "/perolehan/penerimaan/verifikasi",
            //         icon: "",
            //         title: "Space",
            //         fontIcon: "",
            //         children: []
            //     }]
            // },
        ]
    },
    {
        path: "/location",
        icon: "/media/icons/duotune/art/art002.svg",
        title: "Location",
        fontIcon: "bi-archive",
        children: [
            {
                path: "/location/building",
                icon: "",
                title: "Building",
                fontIcon: "",
                children: [{
                    path: "/location/building/room",
                    icon: "",
                    title: "Room",
                    fontIcon: "",
                    children: []
                }]
            },
        ]
    },
    // {
    //     path: "/maintenances-repairs",
    //     icon: "/media/icons/duotune/art/art002.svg",
    //     title: "Maintenances & Repairs",
    //     fontIcon: "bi-archive",
    //     children: [
    //         {
    //             path: "/maintenances-repairs/asset-analysis",
    //             icon: "",
    //             title: "Asset Analysis",
    //             fontIcon: "",
    //             children: []
    //         },
    //         {
    //             path: "/maintenances-repairs/asset-maintenances",
    //             icon: "",
    //             title: "Asset Maintenances",
    //             fontIcon: "",
    //             children: []
    //         },
    //         {
    //             path: "/maintenances-repairs/asset-repairs",
    //             icon: "",
    //             title: "Asset Repairs",
    //             fontIcon: "",
    //             children: []
    //         }
    //     ]
    // },
    // {
    //     path: "/disposal",
    //     icon: "/media/icons/duotune/art/art002.svg",
    //     title: "Disposal",
    //     fontIcon: "bi-archive",
    //     children: [
    //         {
    //             path: "/disposal/lost-assets",
    //             icon: "",
    //             title: "Lost Assets",
    //             fontIcon: "",
    //             children: []
    //         },
    //         {
    //             path: "/disposal/damaged-assets",
    //             icon: "",
    //             title: "Damaged Assets",
    //             fontIcon: "",
    //             children: []
    //         },
    //         {
    //             path: "/disposal/approval",
    //             icon: "",
    //             title: "Disposal Approval",
    //             fontIcon: "",
    //             children: []
    //         },
    //         {
    //             path: "/disposal/supporting-documents",
    //             icon: "",
    //             title: "Supporting Documents",
    //             fontIcon: "",
    //             children: []
    //         }
    //     ]
    // },
    // {
    //     path: "/perencanaan",
    //     icon: "/media/icons/duotune/art/art002.svg",
    //     title: "Perencanaan RKBMN",
    //     fontIcon: "bi-archive",
    //     children: [
    //         {
    //             path: "/perencanaan/pengadaan",
    //             icon: "",
    //             title: "Pengadaan",
    //             fontIcon: "",
    //             children: []
    //         }, {
    //             path: "/perencanaan/pemeliharaan",
    //             icon: "",
    //             title: "Pemeliharaan",
    //             fontIcon: "",
    //             children: []
    //         }
    //     ]
    // },
    // {
    //     path: "/perolehan",
    //     icon: "/media/icons/duotune/general/gen022.svg",
    //     title: "Perolehan Barang",
    //     fontIcon: "bi-archive",
    //     children: [
    //         {
    //             path: "/perolehan/penerimaan",
    //             icon: "",
    //             title: "Penerimaan Barang",
    //             fontIcon: "",
    //             children: [{
    //                 path: "/perolehan/penerimaan/pencatatan",
    //                 icon: "",
    //                 title: "Pencatatan",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/penerimaan/verifikasi",
    //                 icon: "",
    //                 title: "Verifikasi",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/penerimaan/retur",
    //                 icon: "",
    //                 title: "Retur",
    //                 fontIcon: "",
    //                 children: []
    //             }]
    //         },
    //         {
    //             path: "/perolehan/bmn",
    //             icon: "",
    //             title: "BMN",
    //             fontIcon: "",
    //             children: [{
    //                 path: "/perolehan/bmn/pencatatan",
    //                 icon: "",
    //                 title: "Pencatatan",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/bmn/cetak/qr",
    //                 icon: "",
    //                 title: "Cetak Label QR",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/bmn/registrasi",
    //                 icon: "",
    //                 title: "Registrasi",
    //                 fontIcon: "",
    //                 children: []
    //             }]
    //         },
    //         {
    //             path: "/perolehan/sewa",
    //             icon: "",
    //             title: "Sewa",
    //             fontIcon: "",
    //             children: [{
    //                 path: "/perolehan/sewa/pencatatan",
    //                 icon: "",
    //                 title: "Pencatatan",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/sewa/cetak/qr",
    //                 icon: "",
    //                 title: "Cetak Label QR",
    //                 fontIcon: "",
    //                 children: []
    //             }, {
    //                 path: "/perolehan/sewa/registrasi",
    //                 icon: "",
    //                 title: "Registrasi",
    //                 fontIcon: "",
    //                 children: []
    //             }]
    //         }
    //     ]
    // }
];
export default SideMenu