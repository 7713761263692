// import { createStore, applyMiddleware } from 'redux'
import { configureStore } from "@reduxjs/toolkit";

// import thunk from 'redux-thunk'
import rootReducer from './reducer/rootReducer'

// export const store = createStore(rootReducer, applyMiddleware(thunk));
// initial states here
const initalState = {};

const store = configureStore({
    reducer: rootReducer,
    initalState,
    // middleware,
    devTools: process.env.NODE_ENV !== "production",
    // enhancers: [reduxBatch]
});

export default store;