var axios = require('axios');
// import sideNotification from '../helper/sideNotification';

// const isProduction = process.env.NODE_ENV === 'production'
// const hostname = 'localhost'
// const port = process.env.port || 3000

const API_URL = process.env.REACT_APP_API_URL

// var baseURL = "";
// baseURL = process.env.NEXT_PUBLIC_DEVELOPMENT_API_HOST + ":" + process.env.NEXT_PUBLIC_DEVELOPMENT_API_PORT;
// if (isProduction) {
//     baseURL = process.env.NEXT_PUBLIC_PRODUCTION_API_HOST;
// }
// baseURL += "/api/"

// let user = joranCookies.get();
let user = {};

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': user?.token || null,
    },
});

instance.interceptors.response.use((response) => response,
    (error) => {
        // whatever you want to do with the error
        //sideNotification.open("Something went wrong!", "Please check your connection...", false);
        throw error;
    });

export default instance