import Cookies from 'universal-cookie';

// var hostname = window.location.hostname;
const myCookies = new Cookies();
const isProduction = process.env.NODE_ENV === 'production'

// var baseURL = "";
let user;
// baseURL = process.env.NEXT_PUBLIC_DEVELOPMENT_API_HOST + ":" + process.env.NEXT_PUBLIC_DEVELOPMENT_API_PORT;
if (isProduction) {
    user = myCookies.get('simaCookies') || null;
} else {
    user = myCookies.get('simaLocalCookies') || null;
}
let cookies = {
    set(data) {
        if (user) {
            data.token = user.token;
        }

        var maxAge = 7 * 24 * 60 * 60;
        if (!isProduction) {
            //-- Localhost
            myCookies.set(
                'simaLocalCookies',
                JSON.stringify(data),
                {
                    path: '/',
                    maxAge: maxAge,
                    httpOnly: false,
                }
            );
        } else {
            //-- Production
            myCookies.set(
                'simaCookies',
                JSON.stringify(data),
                {
                    path: '/',
                    maxAge: maxAge,
                    httpOnly: false,
                    // domain: ".com"
                }
            );
        }
    },
    get() {
        if (isProduction) {
            return myCookies.get('simaCookies') || null;
        } else {
            return myCookies.get('simaLocalCookies') || null;
        }
    },
    remove() {
        if (isProduction) {
            // myCookies.remove('simaCookies', {
            //     domain: ".com"
            // })
            myCookies.remove('simaCookies')
        } else {
            myCookies.remove('simaLocalCookies')
        }
    }
}
export default cookies