/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage } from '../modules/login'
import App from '../App'
import cookies from '../../config/cookies';

const { PUBLIC_URL } = process.env

const AppRoutes = () => {
	const currentUser = cookies.get();
	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path='error/*' element={<ErrorsPage />} />
					<Route path='logout' element={<Logout />} />

					{currentUser ? (
						<>
							<Route path='/*' element={<PrivateRoutes />} />
							<Route index element={<Navigate to='/dashboard' />} />
						</>
					) : (
						<>
							<Route path='login/*' element={<AuthPage />} />
							<Route path='*' element={<Navigate to='/login' />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
