import React, { useEffect, useState } from 'react'
import 'devextreme/dist/css/dx.light.css';
import DataGrid, {
    Column,
    FormItem,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Export
} from 'devextreme-react/data-grid';

import service from '../data/dummy.js';

const tasks = service.getTasks();

export default function TaskGrid() {

    const [collapsed, setCollapsed] = useState(false);
    const pageSizes = [10, 25, 50, 100];
    useEffect(() => {
    }, []);
    const onContentReady = (e) => {
        if (!collapsed) {
            e.component.expandRow(['EnviroCare']);
            setCollapsed(true);
        }
    }

    const renderActionCell = (e) => {
		return (
			<>
				<a href={`#`} className='fw-bold ms-1'>
					Approve
				</a>
                
                <a href={`#`} className='fw-bold ms-1'>
					Reject
				</a>
			</>
		)
	}

    return (<div className='row'>
        <div className='col-md-12 py-5'>
            <DataGrid
                dataSource={tasks}
                showBorders={true}
                columnAutoWidth={true}
                onContentReady={onContentReady}
            >
                <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={false} />
                
                <Column dataField="Subject" caption="Task Name" />
                <Column dataField="StartDate" dataType="date" />
                <Column dataField="DueDate" dataType="date" />
                <Column dataField="Priority" />
                <Column
						dataField="id"
						caption="Actions"
						showBorders={true}
						allowGrouping={true}
						cellRender={renderActionCell} />

                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={10} />
                {/* <Column
                    caption="Completed"
                    dataType="boolean"
                    calculateCellValue={this.completedValue}
                /> */}
            </DataGrid>
        </div>
    </div>);
}