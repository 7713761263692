import React, { useEffect } from 'react'
import { Navigate, Routes } from 'react-router-dom'
// import { useAuth } from './core/Auth'
import cookies from '../../../config/cookies'

export function Logout() {
  // const {logout} = useAuth()
  useEffect(() => {
    // logout()
    cookies.remove();
    document.location.reload();
    // console.log(cookies.get());
    //window.location.assign("/login")
  }, [])

  return (
    <Routes>
      <Navigate to='/login' />
    </Routes>
  )
}
