/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import Login from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
	useEffect(() => {
		document.body.classList.add('bg-body')
		return () => {
			document.body.classList.remove('bg-body')
		}
	}, [])

	return (
		<div className="row p-0 m-0">
			<div className="col-12" style={{ height: "100vh" }}>
				<div className="card h-100 border-0 justify-content-center" style={{ backgroundColor: "transparent" }}>
					<div className='offset-md-3 col-md-6 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4'>
						<div className="card-body">
							<div className='text-center'>
								<a href='#'>
									<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-polri.png')} className='h-120px' />
								</a>
								<h2 className='mt-5 mb-12'>{process.env.REACT_APP_NAME}</h2>
							</div>

							<div className='shadow-sm p-10'>
								<Outlet />
							</div>
						</div>
						{/* <div className=''>
							<div class="col align-self-center">
							</div>

						</div> */}
					</div>
				</div>
			</div>
		</div>
		// <div className="row p-0 m-0">
		// 	<div className="col-md-5 col-lg-5 col-xl-6 d-none d-md-block" style={{ height: "100vh", backgroundColor: "#E0F3FF" }}>
		// 		<div className="card h-100 border-0 justify-content-center" style={{ backgroundColor: "transparent" }}>
		// 			<div>
		// 				<div className="card-body">
		// 					<div className="text-center">
		// 						{/* <Logo
		// 							size="270px"
		// 							tagShow={true} /> */}
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		// 	className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
		// // style={{
		// // 	backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
		// // }}
		// >
		// 	<div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
		// 		<a href='#' className='mb-2'>
		// 			<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-polri.png')} className='h-120px' />
		// 		</a>
		// 		<h2 className='mb-12'>Asset Management System</h2>

		// <div className='w-lg-500px w-xl-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
		// 	<Outlet />
		// </div>
		// 	</div>
		// </div>
	)
}

const AuthPage = () => (
	<Routes>
		<Route element={<AuthLayout />}>
			<Route path='login' element={<Login />} />
			<Route path='registration' element={<Registration />} />
			<Route path='forgot-password' element={<ForgotPassword />} />
			<Route index element={<Login />} />
		</Route>
	</Routes>
)

export { AuthPage }
