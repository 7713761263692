import React from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

// import { useIntl } from 'react-intl'
// import { KTSVG } from '../../../helpers'

import SideMenu from './data/SideMenu';

export function AsideMenuMain() {
	// const intl = useIntl()
	return (
		<>
			{SideMenu.map(function (menu, _) {
				if (menu.children.length > 0) {
					return (
						<AsideMenuItemWithSub
							to={menu.path}
							title={menu.title}
							icon={menu.icon}
							fontIcon={menu.fontIcon}
							key={`key-${menu.title}`}>
							{menu.children.map(function (children, _) {
								if (children.children.length > 0) {
									return (
										<AsideMenuItemWithSub
											to={children.path}
											title={children.title}
											icon={children.icon}
											fontIcon={children.fontIcon}
											key={`key-${children.title}`}>
											{children.children.map(function (grandchildren, _) {
												return (
													<AsideMenuItem
														to={grandchildren.path}
														title={grandchildren.title}
														icon={grandchildren.icon}
														fontIcon={grandchildren.fontIcon}
														key={`key-${grandchildren.title}`}
														hasBullet={true} />
												)

											})}
										</AsideMenuItemWithSub>
									)
								} else {
									return (
										<AsideMenuItem
											to={children.path}
											title={children.title}
											icon={children.icon}
											fontIcon={children.fontIcon}
											key={`key-${children.title}`}
											hasBullet={true} />
									)
								}
							})}
						</AsideMenuItemWithSub>
					)
				} else {
					return (
						<AsideMenuItem
							to={menu.path}
							title={menu.title}
							icon={menu.icon}
							fontIcon={menu.fontIcon}
							key={`key-${menu.title}`} />
					);
				}

			})}

			{/* Pembayaran Barang */}
			{/* <AsideMenuItemWithSub
				to='/pembayaran/barang'
				title='Pembayaran Barang'
				icon='/media/icons/duotune/general/gen025.svg'
				fontIcon='bi-layers'>
				<AsideMenuItem to='/pembayaran/cetak/faktur' title='Cetak Faktur' hasBullet={true} />
				<AsideMenuItem to='/pembayaran/sp2d/nup' title='Input SP2D & NUP' hasBullet={true} />
				<AsideMenuItem to='/pembayaran/registrasi' title='Registrasi' hasBullet={true} />
			</AsideMenuItemWithSub> */}

			{/* Penyaluran Barang */}
			{/* <AsideMenuItemWithSub
				to='/penyaluran/barang'
				title='Penyaluran Barang'
				fontIcon='bi-archive'
				icon='/media/icons/duotune/general/gen022.svg' >
				<AsideMenuItemWithSub to='/penyaluran/uakpb/asal' title='UAKPB Asal' hasBullet={false}>
					<AsideMenuItem to='/penyaluran/permintaan/barang' title='Permintaan Barang' hasBullet={true} />
					<AsideMenuItem to='/penyaluran/persediaan/keluar' title='Persediaan Keluar' hasBullet={true} />
					<AsideMenuItem to='/penyaluran/transfer/keluar' title='Transfer Keluar' hasBullet={true} />
					<AsideMenuItem to='/penyaluran/persetujuan/transfer' title='Persetujuan Transfer Keluar' hasBullet={true} />
					<AsideMenuItem to='/penyaluran/berita/acara' title='Berita Acara Transfer Keluar' hasBullet={true} />
				</AsideMenuItemWithSub>

				<AsideMenuItem to='/penyaluran/uakpb/tujuan' title='UAKPB Tujuan' hasBullet={false} />
			</AsideMenuItemWithSub> */}

			{/* <AsideMenuItem
				to='/pengelolaan/barang'
				icon='/media/icons/duotune/art/art002.svg'
				title="Pengelolaan Barang"
				fontIcon='bi-app-indicator' />

			<AsideMenuItem
				to='/perpindahan/barang'
				icon='/media/icons/duotune/art/art002.svg'
				title="Perpindahaan Barang"
				fontIcon='bi-app-indicator' />

			<AsideMenuItem
				to='/barang/hilang'
				icon='/media/icons/duotune/art/art002.svg'
				title="Barang Hilang"
				fontIcon='bi-app-indicator' />

			<AsideMenuItem
				to='/penghapusan/barang'
				icon='/media/icons/duotune/art/art002.svg'
				title="Penghapusan Barang"
				fontIcon='bi-app-indicator' />

			<AsideMenuItem
				to='/laporan'
				icon='/media/icons/duotune/art/art002.svg'
				title="Laporan"
				fontIcon='bi-app-indicator' /> */}

			{/* <div className='menu-item'>
				<div className='menu-content pt-8 pb-2'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>Master</span>
				</div>
			</div> */}

			{/* <AsideMenuItem
				to='/vendor'
				icon='/media/icons/duotune/finance/fin001.svg'
				title='Vendor'
				fontIcon='bi-layers' /> */}


			{/* <div className='menu-item'>
				<div className='menu-content pt-8 pb-2'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>Components</span>
				</div>
			</div>
			<AsideMenuItemWithSub
				to='/crafted/pages'
				title='Pages'
				fontIcon='bi-archive'
				icon='/media/icons/duotune/general/gen022.svg' >
				<AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
					<AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
					<AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
					<AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
					<AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
					<AsideMenuItem
						to='/crafted/pages/profile/connections'
						title='Connections'
						hasBullet={true} />
				</AsideMenuItemWithSub>

				<AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
					<AsideMenuItem
						to='/crafted/pages/wizards/horizontal'
						title='Horizontal'
						hasBullet={true} />
					<AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSub> */}
			{/* <AsideMenuItemWithSub
				to='/crafted/accounts'
				title='Accounts'
				icon='/media/icons/duotune/communication/com006.svg'
				fontIcon='bi-person'>
				<AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
				<AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
			</AsideMenuItemWithSub>

			<AsideMenuItemWithSub
				to='/crafted/widgets'
				title='Widgets'
				icon='/media/icons/duotune/general/gen025.svg'
				fontIcon='bi-layers'>
				<AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
				<AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
				<AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
				<AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
				<AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
				<AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
			</AsideMenuItemWithSub> */}
			{/* <div className='menu-item'>
				<div className='menu-content pt-8 pb-2'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>System Management</span>
				</div>
			</div>

			<AsideMenuItem
				to='/apps/user-management/users'
				icon='/media/icons/duotune/communication/com006.svg'
				title='User management'
				fontIcon='bi-layers' /> */}

			{/* <AsideMenuItemWithSub
				to='/error'
				title='Errors'
				fontIcon='bi-sticky'
				icon='/media/icons/duotune/general/gen040.svg'
			>
				<AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
				<AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
			</AsideMenuItemWithSub> */}
			{/* <div className='menu-item'>
				<div className='menu-content'>
					<div className='separator mx-1 my-4'></div>
				</div>
			</div> */}
		</>
	)
}
