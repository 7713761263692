import React from 'react'
import { useLayout } from '../../core/LayoutProvider'
import { MainToolbar } from './MainToolbar'

const Toolbar = () => {
	const { config } = useLayout()

	switch (config.toolbar.layout) {
		case 'toolbar1':
			return <MainToolbar />

		default:
			return <MainToolbar />
	}
}

export { Toolbar }
